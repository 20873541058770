import Q from 'q';
// 去实名
import web_esIgn_esIgnRealNameController_buyerRealName from '@/lib/data-service/default/web_esIgn_esIgnRealNameController_buyerRealName.js';
// 查询实名情况
import web_esIgn_esIgnRealNameController_queryAuthentication from '@/lib/data-service/default/web_esIgn_esIgnRealNameController_queryAuthentication.js';

export default {
  data () {
    return {
      detail: {},
      realNameUrl: '',
    }
  },
  methods: {
    req_detail () {
      const _this = this;
      Q.when()
        .then(function () {
          return web_esIgn_esIgnRealNameController_queryAuthentication();
        })
        .then(function (res) {
          _this.detail = res;
        })
    },
    req_url () {
      const _this = this;
      Q.when()
        .then(function () {
          return web_esIgn_esIgnRealNameController_buyerRealName();
        })
        .then(function (res) {
          _this.realNameUrl = res.url;
        })
    },
    to_realName () {
      window.open(this.realNameUrl, '_blank');
    }
  },
  mounted () {
    this.req_url();
    this.req_detail();
  }
}