const Q = require(`q`);
const _ = require(`underscore`);
const __request = require(`./__request`);

// 公司查询认证信息实名接口文档地址：http://192.168.0.9:8080/doc.html#/yinzhilv_travel/es-ign-real-name-controller/QueryAuthenticationUsingPOST
const web_esIgn_esIgnRealNameController_queryAuthentication = (pParameter) => {

  if (!pParameter) pParameter = {};

  const p = {
    method: 'post',
    urlSuffix: '/web/esIgn/esIgnRealNameController/queryAuthentication',
    data: pParameter,
  };
  return Q.when()
    .then(function () {
      return __request(p);
    })
    .then(function (data) {
      return Q.resolve(data);
    })
    .catch(function (ex) {
      console.error(ex);
      if (ex) {
        if (ex.stack) {
          console.error(ex.stack);
        }
      }
      return Q.reject(ex);
    })
    ;

};
module.exports = web_esIgn_esIgnRealNameController_queryAuthentication;
